


import appApi from '@/modules/api/appApi'
import appTempApiToken from '@/modules/api/appTempApiToken'

export const login2FA = async ( credentials ) => {
  const { data } = await appApi.post('/auth/login-2fa', credentials)
  return data
}

export const resetPassword = async ( params ) => {
  const { data } = await appTempApiToken.post('/auth/reset', params )
}


export const verifyCode = async ( params ) => {
  const { data } = await appApi.post('/auth/verify-code', params )
  return data
}


/*
2023-06-11 | fg | forgot password
params: email
response: message, uuid (verification code)
 */
export const forgotPassword = async ( params ) => {
  const { data } = await appApi.post('/auth/forgot-password', params )
  return data
}


/*
2023-06-11 | fg | forgot password verification code
params: uuid, code
response: verified_at, user
*/
export const forgotPasswordVerifyCode = async ( params ) => {
  const { data } = await appApi.post('/auth/forgot-password-verify-code', params )
  return data
}

/*
2023-06-11 | fg | forgot password
params: uuid user, password, confirm_password
response: message(200)
 */
export const forgotPasswordReset = async ( params ) => {
  const { data } = await appApi.post('/auth/forgot-password-reset', params )
  return data
}
