
import axios from 'axios'


const appTempApiToken = axios.create({
  // You can add your headers here
  // ================================
  //baseURL: 'http://127.0.0.1:8000/api',
  baseURL: 'http://apisga.coop23dejulio.fin.ec/api',
  //baseURL: 'http://apisga-test.coop23dejulio.fin.ec/api',
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
})


// Obtiene el token de localStorage
const token = localStorage.getItem('reset_token');

if (token) {
  // Agrega el token como un encabezado personalizado
  appTempApiToken.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}


export default appTempApiToken
